import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import { FileService } from './filesaver.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {
  constructor(private datePipe: DatePipe, private _file: FileService) { }

  saveExcel = (name: string, data: any) => this.exportAsExcelFile(data, name + '_' + this.getNewDate());

  saveObservableExcel = (name: string, observable: Observable<any>) => observable.subscribe(res => this.exportAsExcelFile(res, name + '_' + this.getNewDate()));

  private getNewDate = (): string => this.datePipe.transform(new Date(), 'MMMM d, y') + ''; //handles new date

  private exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    this._file.Save(data, excelFileName, EXCEL_EXTENSION);
  } //saving new excel with xlsx buffer and workbooks

}
